export default {
	header: {
		self: {},
		items: [],
	},
	aside: {
		self: {},
		items: [
			{
				title: "Review Test",
				root: true,
				icon: "flaticon2-sheet",
				page: "dashboard",
				permission: "marker",
			},
			{
				title: "Dashboard",
				root: true,
				icon: "flaticon2-architecture-and-city",
				page: "dashboard",
				translate: "MENU.DASHBOARD",
				permission: "dashboard.view",
				bullet: "dot",
			},
			{
				title: "Course Categories",
				root: true,
				icon: "flaticon-open-box",
				bullet: "dot",
				permission: ["property.view", "industry.view", "level.view"],
				submenu: [
					{
						title: "Industry",
						page: "industry/list",
						permission: "industry.view",
					},
					{
						title: "Level",
						page: "level/list",
						permission: "level.view",
					},
				],
			},
			{
				title: "Course Management",
				root: true,
				icon: "flaticon-presentation-1",
				permission: ["course", "course_online.view", "course_onsite.view", "course_analytics.view"],
				bullet: "dot",
				submenu: [
					{
						title: "Online Course",
						page: "courses/list-online",
						permission: "course_online.view",
					},
					{
						title: "Live Training",
						page: "courses/list-onsite",
						permission: "course_onsite.view",
					},
					{
						title: "Course Analytics",
						page: "course/analytics",
						permission: "course_analytics.view",
					},
				],
			},
			{
				title: "Question Bank",
				root: true,
				icon: "flaticon-open-box",
				page: "question-bank/list",
				permission: "question_bank.view",
			},
			{
				title: "Course Builder",
				root: true,
				icon: "flaticon2-sheet",
				page: "course-builder/list",
				permission: "course_builder.view",
			},
			{
				title: "Quiz Management",
				root: true,
				icon: "flaticon2-sheet",
				page: "quiz/list",
				permission: "exam.view",
			},
			{
				title: "Program Management",
				root: true,
				icon: "flaticon-calendar",
				page: "training/list",
				bullet: "dot",
				permission: "training.view",
			},
			{
				title: "User Management",
				root: true,
				icon: "flaticon-users",
				bullet: "dot",
				permission: ["user_mngt", "role.view", "admin.view", "user.view", "marker.view"],
				submenu: [
					{
						title: "Admin",
						page: "admin/list",
						permission: "admin.view",
					},
					{
						title: "Users",
						page: "users/list",
						permission: "user.view",
					},
					{
						title: "Role & Permission",
						page: "role/list",
						permission: "role.view",
					},
					{
						title: "Markers",
						page: "marker/list",
						permission: "marker.view",
					},
					{
						title: "Agent",
						root: true,
						page: "agent/list",
						permission: "agent.view",
					},
				],
			},
			{
				title: "Subscription Management",
				root: true,
				icon: "flaticon-list",
				bullet: "dot",
				permission: ["subscripton", "plan.view", "storage.view", "feature.view"],
				submenu: [
					{
						title: "Subscription Plan",
						page: "plan/list",
						permission: "plan.view",
					},
					{
						title: "Storage Management",
						page: "storage/list",
						permission: "storage.view",
					},
					{
						title: "Feature Plan",
						page: "plan/feature",
						permission: "feature.view",
					},
				],
			},
			{
				title: "CE Management",
				root: true,
				icon: "fa fa-user-friends",
				page: "corporate/list",
				permission: "ce.view",
				badge: {
					type: "kt-badge--md kt-badge--rounded ce-request",
					value: 0,
				},
			},
			{
				title: "Commercial Partners",
				root: true,
				icon: "flaticon-interface-7",
				page: "commercial-partners",
				permission: "commercial.view",
			},
			{
				title: "TSE Management",
				root: true,
				icon: "flaticon-customer",
				page: "certification-body/list",
				permission: "tse.view",
				badge: {
					type: "kt-badge--md kt-badge--rounded cb-request",
					value: 0,
				},
			},
			{
				title: "Sale Management",
				root: true,
				icon: "flaticon-cart",
				bullet: "dot",
				permission: ["sale", "promotion.view", "order.view", "revenue.view"],
				submenu: [
					{
						title: "Promotion Management",
						page: "promotion/list",
						permission: "promotion.view",
					},
					{
						title: "Order Management",
						page: "order/list",
						permission: "order.view",
					},
					{
						title: "Company Revenue",
						page: "company-revenue",
						permission: "revenue.view",
					},
				],
			},
			{
				title: "Inquiry Management",
				root: true,
				icon: "flaticon-paper-plane",
				page: "inquiry/list",
				badge: {
					type: "kt-badge--md kt-badge--rounded inquiryStatusNew",
					value: 0,
				},
				permission: "inquiry.view",
			},
			{
				title: "Mass Com",
				root: true,
				icon: "flaticon-email",
				page: "mass-comm/list",
				permission: "mass_com.view",
			},
			{
				title: "Report",
				root: true,
				icon: "flaticon-doc",
				page: "report-overview",
				permission: "report.view",
			},
			{
				title: "Sales Report",
				root: true,
				icon: "flaticon-graphic-2",
				page: "sales-report",
				permission: "sale_report.view",
			},
			{
				title: "Setting",
				root: true,
				icon: "flaticon-settings",
				page: "setting",
				permission: "setting.view",
			},
			{
				title: "Referral Code",
				root: true,
				icon: "flaticon2-lorry",
				page: "referral/list",
				permission: "referral_code.view",
			},
			// {
			// 	title: "Advertisement",
			// 	root: true,
			// 	icon: "flaticon-star",
			// 	page: "ads/list",
			// 	permission: "adv.view",
			// },
		],
	},
};
