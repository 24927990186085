/* eslint-disable no-mixed-operators */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

class UserProfile extends React.Component {
	render() {
		const { user, showHi, showAvatar } = this.props;
		return (
			<Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
				<Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
					<div className="kt-header__topbar-user">
						{showHi && <span className="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>}

						{showHi && <span className="kt-header__topbar-username kt-hidden-mobile">{user.first_name}</span>}

						{showAvatar && <img alt="Avatar" src={(user.avatar && user.avatar) || "/media/users/default.jpg"} style={{ width: "34px", height: "34px" }} />}
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
					{/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
					<div
						className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
						style={{
							backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`,
						}}
					>
						<div className="kt-user-card__badge"></div>
					</div>
					<div className="kt-notification">
						{/* <Link className="kt-notification__item" to="/">
							<div className="kt-notification__item-icon">
								<i className="flaticon2-calendar-3 kt-font-success" />
							</div>
							<div className="kt-notification__item-details">
								<div className="kt-notification__item-title kt-font-bold">Infomation</div>
								<div className="kt-notification__item-time">User Info</div>
							</div>
						</Link> */}
						<div className="kt-notification__custom">
							<Link to="/logout" className="btn btn-label-brand btn-sm btn-bold">
								Logout
							</Link>
						</div>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

const mapStateToProps = ({ auth: { user } }) => ({
	user,
});

export default connect(mapStateToProps)(UserProfile);
