/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { WarningOutlined } from "@ant-design/icons";
import makeRequest from "../../libs/request";
import { showErrorMessage, showSuccessMessageIcon } from "../../actions/notification";
import PageHeader from "../../common/antd/component/PageHeader";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../loading";
import checkPermission from "../../libs/permission";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@material-ui/core";
// import { isValidPhoneNumber } from "react-phone-number-input";
import { FormattedMessage } from "react-intl";
import { Spin, Modal, Select } from "antd";
import { Form } from "react-bootstrap";
const { Option } = Select;

const useStyles1 = makeStyles((theme) => ({
	root: {
		width: "100%",
		marginTop: theme.spacing(3),
		overflowX: "auto",
	},
	table: {
		minWidth: 650,
	},
}));

export default function ListCompany(props) {
	// Example 1
	const classes1 = useStyles1();
	const [page, setPage] = React.useState(0);
	const [size, setSize] = React.useState(10);
	const [totalRows, setTotalRows] = React.useState(0);
	const [rows, setRow] = useState([]);
	const [visible, setVisible] = useState(false);
	const [dataSearch, setDataSearch] = useState({ loadingPage: true, loadingTable: false, type: "ce" });
	const [dataDelete, setDataDelete] = useState({ visible: false });
	const [fetching, setFetching] = useState(false);
	const [cbList, setcbList] = useState([]);
	const [corpList, setcorpList] = useState([]);
	const [textSearch, setTextSearch] = useState("");
	const [dataAdd, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const permissions = {
		view: "commercial.view",
		create: "commercial.create",
		delete: "commercial.delete",
	};

	const getData = (page, perPage, keyword, status) => {
		makeRequest("get", `commercial-partners/getList`, { page, perPage, keyword, status, inCms: true })
			.then(({ data }) => {
				if (data.signal) {
					setRow(data.data.list);
					setTotalRows(data.data.total);
					setLoading(false);
				} else {
					return showErrorMessage(data.message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const getDataList = (name) => {
		makeRequest("get", `cb/getListCreateAdmin`, { name, company: true })
			.then(({ data }) => {
				if (data.signal) {
					let cb = data.data.cb.map((it) => {
						return {
							label: `${it.name}`,
							value: `${it.id}_cb`,
						};
					});
					let corp = data.data.corporates.map((it) => {
						return {
							label: `${it.name}`,
							value: `${it.id}_cor`,
						};
					});
					setcbList(cb);
					setcorpList(corp);
					setFetching(false);
					setTextSearch(name);
				}
			})
			.catch((err) => {
				console.log("++++++++++++++++", err);
			});
	};
	useEffect(() => {
		if (checkPermission(permissions.view)) {
			getData(page, size, "", "all");
			getDataList("");
		} else {
			return showErrorMessage("No Permission.");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const unfilteredData = () => {
		setPage(0);
		setSize(10);
		setDataSearch({
			...dataSearch,
			status: "all",
			keyword: "",
		});
		getData(page, size, "", "all");
	};
	const handleChangePage = (event, newPage) => {
		getData(newPage, size, dataSearch.keyword, dataSearch.status);
		setPage(newPage);
	};

	const handleChangeSize = (event) => {
		setSize(parseInt(event.target.value, 10));
		setPage(0);
		getData(0, parseInt(event.target.value, 10), dataSearch.keyword, dataSearch.status);
	};

	const onChangeValue = (key, value) => {
		if (key === "type") {
			setData({
				...dataAdd,
				sub_id: null,
			});
		}
		setDataSearch({
			...dataSearch,
			[key]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onChangeValue("loadingTable", true);
		getData(page, size, dataSearch.keyword, dataSearch.status);
		onChangeValue("loadingTable", false);
	};
	const showModal = (idDel) => {
		setDataDelete({
			...dataDelete,
			visible: true,
			idDel,
		});
	};

	const clickModalCancel = () => {
		setDataDelete({
			...dataDelete,
			visible: false,
			idDel: 0,
		});
	};

	const clickModalOk = () => {
		let id = dataDelete.idDel;
		makeRequest("post", `commercial-partners/destroy`, { id })
			.then(({ data }) => {
				if (data.signal) {
					showSuccessMessageIcon("Deleted successfully");
					setDataDelete({
						...dataDelete,
						visible: false,
						idDel: 0,
					});
					unfilteredData();
					setData({});
					getDataList("");
				} else {
					return showErrorMessage("Error", data.message);
				}
			})

			.catch((err) => {
				console.log("error", err);
			});
	};
	const renderStatusText = (row) => {
		if (row.status === 1) {
			return (
				<span className="btn btn-label-primary btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.ACTIVE" />
				</span>
			);
		} else if (row.status === 2) {
			return (
				<span className="btn btn-label-primary btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.APPROVED" />
				</span>
			);
		} else if (row.status === 3) {
			return (
				<span className="btn btn-label-warning btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.PENDING" />
				</span>
			);
		} else if (row.status === 4) {
			return (
				<span className="btn btn-label-danger btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.REJECTED" />
				</span>
			);
		} else if (row.status === 5) {
			return (
				<span className="btn btn-label-danger btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.CANCEL" />
				</span>
			);
		} else {
			return (
				<span
					className="btn btn-label-warning btn-bold btn-sm btn-icon-h"
					style={{ borderRadius: ".42rem", width: 70 }}
				>
					<FormattedMessage id="PAGE.LABLE.INACTIVE" />
				</span>
			);
		}
	};

	const fetchCB = (value) => {
		setcbList([]);
		setcorpList([]);
		setFetching(true);
		getDataList(value);
	};

	const onChangeCB = (value) => {
		const arrKey = value.split("_");
		let cb_id,
			corporate_id = null;
		if (dataSearch.type === "ce") {
			cb_id = null;
			corporate_id = arrKey[0];
		} else {
			cb_id = arrKey[0];
			corporate_id = null;
		}
		setData({
			...dataAdd,
			sub_id: value,
			corporate_id,
			cb_id,
			err_sub_id: false,
		});
	};
	const clickModalCreate = () => {
		if (!dataAdd.sub_id) {
			return showErrorMessage("Error", "Please choose a Partner name");
		}
		makeRequest("post", `commercial-partners/store`, dataAdd)
			.then(({ data }) => {
				if (data.signal) {
					setVisible(false);
					showSuccessMessageIcon("Add company successfully");
					getData(page, size, "");
					clickModalCancelCreate();
					getDataList("");
				} else {
					return showErrorMessage("Error", data.message);
				}
			})

			.catch((err) => {
				console.log("error", err);
			});
	};
	const clickModalCancelCreate = () => {
		setVisible(false);
		setDataSearch({
			...dataSearch,
			type: "ce",
		});
		setData({
			...dataAdd,
			sub_id: null,
		});
	};

	if (loading) {
		return <Loading />;
	}
	return (
		<div className="row">
			<div className="col-md-12">
				<PageHeader title={`Commercial Partners`} breadcrumb={[{ title: "Commercial Partners", link: "" }]} />
			</div>

			<div className="col-md-12">
				<div className="kt-section">
					<div className="kt-section__content">
						<Paper className={classes1.root}>
							<div className="col-md-12">
								<Form onSubmit={handleSubmit} style={{ marginBottom: -35 }}>
									<div style={{ marginTop: 20, fontSize: 20 }}>
										<label>
											<FormattedMessage id="PAGE.BUTTON.SPAN.SEARCH" />
										</label>
									</div>
									<div className="form-row">
										<div className="form-group col-md-6">
											<div className="form-group" style={{ display: "flex" }}>
												<input
													type="text"
													autoFocus
													onChange={(e) => onChangeValue("keyword", e.target.value)}
													className="form-control inline-block"
													placeholder="Keyword"
													name="keyword"
													value={dataSearch.keyword || ""}
												/>
											</div>
										</div>
										<div className="form-group col-md-6" style={{ marginLeft: -5 }}>
											<div className="form-group" style={{ display: "flex" }}>
												{/* <select
													className="form-control inline-block"
													onChange={(e) => onChangeValue("status", e.target.value)}
													value={dataSearch.status || "all"}
													style={{ width: "60%" }}
												>
													<FormattedMessage id="PAGE.LABLE.ALL_STATUS">
														{(message) => <option value="all">{message}</option>}
													</FormattedMessage>
													<FormattedMessage id="PAGE.LABLE.ACTIVE">
														{(message) => <option value="1">{message}</option>}
													</FormattedMessage>
													<FormattedMessage id="PAGE.LABLE.INACTIVE">
														{(message) => <option value="0">{message}</option>}
													</FormattedMessage>
													<FormattedMessage id="PAGE.LABLE.CANCEL">
														{(message) => <option value="5">{message}</option>}
													</FormattedMessage>
												</select> */}

												<button
													className="btn btn-label-primary btn-bold btn-icon-h kt-margin-l-5"
													onClick={unfilteredData}
													type="button"
												>
													<span>
														<FormattedMessage id="PAGE.BUTTON.SPAN.UNFILTERED" />
													</span>
												</button>
												<button className="btn btn-label-primary btn-bold btn-icon-h kt-margin-l-5" type="submit">
													<span>
														<FormattedMessage id="PAGE.BUTTON.SPAN.SEARCH" />
													</span>
												</button>
												{checkPermission(permissions.create) && (
													<button
														onClick={() => setVisible(true)}
														className="btn btn-label-warning btn-bold btn-icon-h kt-margin-l-5"
														type="button"
														style={{ position: "absolute", right: 10 }}
													>
														<span>Add Company</span>
													</button>
												)}
											</div>
										</div>
									</div>
								</Form>
								<Table className={classes1.table}>
									<TableHead>
										<TableRow>
											<TableCell>Company Name</TableCell>
											<TableCell>Type Company</TableCell>
											<TableCell>Country</TableCell>
											<TableCell>Subdomain</TableCell>
											<TableCell>
												<FormattedMessage id="TABLE.CELL.STATUS" />
											</TableCell>
											{checkPermission(permissions.delete) ? <TableCell style={{ width: 200 }}>Action</TableCell> : ""}
										</TableRow>
									</TableHead>
									<TableBody>
										{dataSearch.loadingTable ? (
											<TableRow>
												<TableCell colSpan={5} align="center">
													<Spin tip="Loading..." />
												</TableCell>
											</TableRow>
										) : rows.length ? (
											rows.map((row) => (
												<TableRow key={row.id}>
													<TableCell>
														<div style={{ display: "flex" }}>
															<div
																style={{
																	background: `url(${
																		row.cb ? row.cb.logo : row.corporate.logo
																	}) center center no-repeat`,
																	backgroundSize: "contain",
																	height: 50,
																	width: 50,
																}}
															></div>
															<span style={{ paddingTop: 15 }}>
																&nbsp;&nbsp;&nbsp;
																{row.cb ? row.cb.name : row.corporate.name}
															</span>
														</div>
													</TableCell>
													<TableCell>{row.cb ? "Training Sales Entity" : "Competency Entity"}</TableCell>
													<TableCell>{row.cb ? row.cb.country : row.corporate.country}</TableCell>
													<TableCell>{row.cb ? row.cb.sub_domain : row.corporate.sub_domain}</TableCell>
													<TableCell>{renderStatusText(row.cb ? row.cb : row.corporate)}</TableCell>
													{checkPermission(permissions.delete) ? (
														<TableCell>
															<span
																className="hover-span-action-remove"
																onClick={(e) => showModal(row.id)}
																data-toggle="tooltip"
																data-placement="top"
																title="Delete Company"
															>
																Remove
															</span>
														</TableCell>
													) : (
														""
													)}
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={5} align="center">
													<FormattedMessage id="TABLE.CELL.NO_DATA_TO_DISPLAY" />
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50]}
									component="div"
									count={totalRows}
									rowsPerPage={size}
									page={page}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeSize}
								/>
							</div>
						</Paper>
					</div>
				</div>
			</div>
			<Modal
				title="Add Company"
				centered
				visible={visible}
				cancelButtonProps={{ style: { display: "none" } }}
				okText="Confirm"
				maskClosable={false}
				onOk={clickModalCreate}
				onCancel={clickModalCancelCreate}
			>
				<div className="modal-body">
					<div className="form-group">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<h5 className="text-primary" style={{ lineHeight: "41px" }}>
										Type Company
									</h5>
									<select
										className="form-control inline-block"
										onChange={(e) => onChangeValue("type", e.target.value)}
										value={dataSearch.type || "all"}
										style={{ width: "60%" }}
									>
										<option value="ce">Competency Entity</option>
										<option value="tse">Training Sales Entity</option>
									</select>
								</div>
							</div>
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ marginTop: 20 }}>
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<h5 className="text-primary" style={{ marginTop: 10 }}>
										Partner
									</h5>
									<Select
										className={
											dataAdd.err_sub_id
												? "has-error-select form-control partner-select"
												: "form-control partner-select"
										}
										showSearch
										value={dataAdd.sub_id}
										placeholder="Select partner"
										notFoundContent={fetching ? <Spin size="small" /> : textSearch ? "No data" : null}
										filterOption={false}
										onSearch={fetchCB}
										onChange={onChangeCB}
										style={{ width: "60%" }}
									>
										{dataSearch.type === "ce"
											? corpList.map((d) => (
													<Option key={`cor-${d.value}`} value={d.value}>
														{d.label}
													</Option>
											  ))
											: cbList.map((d) => (
													<Option key={`cb-${d.value}`} value={d.value}>
														{d.label}
													</Option>
											  ))}
									</Select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				title="Delete Partner"
				visible={dataDelete.visible}
				onOk={clickModalOk}
				onCancel={clickModalCancel}
				cancelText="Cancel"
				okText="Ok"
			>
				<p>
					<FormattedMessage id="MODEL.P.DO_DELETE" />
				</p>
				<p style={{ display: "flex" }}>
					<WarningOutlined style={{ paddingRight: 5, marginTop: 3 }} /> Note: You can undo this action
				</p>
			</Modal>
		</div>
	);
}
