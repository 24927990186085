/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import makeRequest from "../../libs/request";
import { Link } from "react-router-dom";
import { showErrorMessage } from "../../actions/notification";
import PageHeader from "../../common/antd/component/PageHeader";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../loading";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@material-ui/core";
// import { isValidPhoneNumber } from "react-phone-number-input";
import { FormattedMessage } from "react-intl";
import { Spin } from "antd";
import { Form } from "react-bootstrap";

const useStyles1 = makeStyles((theme) => ({
	root: {
		width: "100%",
		marginTop: theme.spacing(3),
		overflowX: "auto",
	},
	table: {
		minWidth: 650,
	},
}));

export default function ListCompany(props) {
	// Example 1
	const classes1 = useStyles1();
	const [page, setPage] = React.useState(0);
	const [size, setSize] = React.useState(10);
	const [totalRows, setTotalRows] = React.useState(0);
	const [rows, setRow] = useState([]);
	const [dataSearch, setDataSearch] = useState({ loadingPage: true, loadingTable: false });

	const getData = (page, perPage, keyword, status) => {
		makeRequest("get", `corporate/getPartner`, { page, perPage, keyword, status, type: "mtc" })
			.then(({ data }) => {
				if (data.signal) {
					setRow(data.data.list);
					setTotalRows(data.data.total);
				} else {
					return showErrorMessage(data.message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		getData(page, size, "", "all");
		onChangeValue("loadingPage", false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const unfilteredData = () => {
		setPage(0);
		setSize(10);
		setDataSearch({
			...dataSearch,
			status: "all",
			keyword: "",
		});
		getData(page, size, "", "all");
	};
	const handleChangePage = (event, newPage) => {
		getData(newPage, size, dataSearch.keyword, dataSearch.status);
		setPage(newPage);
	};

	const handleChangeSize = (event) => {
		setSize(parseInt(event.target.value, 10));
		setPage(0);
		getData(0, parseInt(event.target.value, 10), dataSearch.keyword, dataSearch.status);
	};

	const onChangeValue = (key, value) => {
		setDataSearch({
			...dataSearch,
			[key]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onChangeValue("loadingTable", true);
		getData(page, size, dataSearch.keyword, dataSearch.status);
		onChangeValue("loadingTable", false);
	};

	const renderStatusText = (row) => {
		if (row.status === 1) {
			return (
				<span className="btn btn-label-primary btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.ACTIVE" />
				</span>
			);
		} else if (row.status === 2) {
			return (
				<span className="btn btn-label-primary btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.APPROVED" />
				</span>
			);
		} else if (row.status === 3) {
			return (
				<span className="btn btn-label-warning btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.PENDING" />
				</span>
			);
		} else if (row.status === 4) {
			return (
				<span className="btn btn-label-danger btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.REJECTED" />
				</span>
			);
		} else if (row.status === 5) {
			return (
				<span className="btn btn-label-danger btn-bold btn-sm btn-icon-h" style={{ borderRadius: ".42rem" }}>
					<FormattedMessage id="PAGE.LABLE.CANCEL" />
				</span>
			);
		} else {
			return (
				<span
					className="btn btn-label-warning btn-bold btn-sm btn-icon-h"
					style={{ borderRadius: ".42rem", width: 70 }}
				>
					<FormattedMessage id="PAGE.LABLE.INACTIVE" />
				</span>
			);
		}
	};

	if (dataSearch.loadingPage) {
		return <Loading />;
	}
	return (
		<div className="row">
			<div className="col-md-12">
				<PageHeader
					title={`Competency Entity Listing`}
					breadcrumb={[{ title: "Competency Entity Listing", link: "" }]}
				/>
			</div>

			<div className="col-md-12">
				<div className="kt-section">
					<div className="kt-section__content">
						<Paper className={classes1.root}>
							<div className="col-md-12">
								<Form onSubmit={handleSubmit} style={{ marginBottom: -35 }}>
									<div style={{ marginTop: 20, fontSize: 20 }}>
										<label>
											<FormattedMessage id="PAGE.BUTTON.SPAN.SEARCH" />
										</label>
									</div>
									<div className="form-row">
										<div className="form-group col-md-4">
											<div className="form-group" style={{ display: "flex" }}>
												<input
													type="text"
													autoFocus
													onChange={(e) => onChangeValue("keyword", e.target.value)}
													className="form-control inline-block"
													placeholder="Keyword"
													name="keyword"
													value={dataSearch.keyword || ""}
												/>
											</div>
										</div>
										<div className="form-group col-md-8" style={{ marginLeft: -5 }}>
											<div className="form-group" style={{ display: "flex" }}>
												<select
													className="form-control inline-block"
													onChange={(e) => onChangeValue("status", e.target.value)}
													value={dataSearch.status || "all"}
													style={{ width: "60%" }}
												>
													<FormattedMessage id="PAGE.LABLE.ALL_STATUS">
														{(message) => <option value="all">{message}</option>}
													</FormattedMessage>
													<FormattedMessage id="PAGE.LABLE.ACTIVE">
														{(message) => <option value="1">{message}</option>}
													</FormattedMessage>
													<FormattedMessage id="PAGE.LABLE.INACTIVE">
														{(message) => <option value="0">{message}</option>}
													</FormattedMessage>
													<FormattedMessage id="PAGE.LABLE.CANCEL">
														{(message) => <option value="5">{message}</option>}
													</FormattedMessage>
												</select>

												<button
													className="btn btn-label-primary btn-bold btn-icon-h kt-margin-l-5"
													onClick={unfilteredData}
													type="button"
												>
													<span>
														<FormattedMessage id="PAGE.BUTTON.SPAN.UNFILTERED" />
													</span>
												</button>
												<button className="btn btn-label-primary btn-bold btn-icon-h kt-margin-l-5" type="submit">
													<span>
														<FormattedMessage id="PAGE.BUTTON.SPAN.SEARCH" />
													</span>
												</button>
											</div>
										</div>
									</div>
								</Form>
								<Table className={classes1.table}>
									<TableHead>
										<TableRow>
											<TableCell>Company Name</TableCell>
											<TableCell>Country</TableCell>
											<TableCell>Subdomain</TableCell>
											<TableCell>
												<FormattedMessage id="TABLE.CELL.STATUS" />
											</TableCell>
											<TableCell style={{ width: 200 }}>
												<FormattedMessage id="TABLE.CELL.ACTION" />
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{dataSearch.loadingTable ? (
											<TableRow>
												<TableCell colSpan={5} align="center">
													<Spin tip="Loading..." />
												</TableCell>
											</TableRow>
										) : rows.length ? (
											rows.map((row) => (
												<TableRow key={row.id}>
													<TableCell>
														<div style={{ display: "flex" }}>
															<div
																style={{
																	background: `url(${row.logo}) center center no-repeat`,
																	backgroundSize: "contain",
																	height: 50,
																	width: 50,
																}}
															></div>
															<span style={{ paddingTop: 15 }}>
																&nbsp;&nbsp;&nbsp;
																{row.name}
															</span>
														</div>
													</TableCell>
													<TableCell>{row.country}</TableCell>
													<TableCell>{row.sub_domain}</TableCell>
													<TableCell>{renderStatusText(row)}</TableCell>
													<TableCell>
														<Link
															to={`/company/detail/${row.id}`}
															className="hover-span-action"
															data-toggle="tooltip"
															data-placement="top"
															title="View CE"
														>
															View
														</Link>
													</TableCell>
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell colSpan={5} align="center">
													<FormattedMessage id="TABLE.CELL.NO_DATA_TO_DISPLAY" />
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50]}
									component="div"
									count={totalRows}
									rowsPerPage={size}
									page={page}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeSize}
								/>
							</div>
						</Paper>
					</div>
				</div>
			</div>
		</div>
	);
}
